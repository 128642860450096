import { gql } from "@apollo/client"

const TitleParagraphQuery = gql`
  query ($id: String!) {
    titleParagraph(id: $id) {
      title
      font
      hideLine
      paragraph
      buttonTitle
      buttonUrl
      button {
        title
        type
        url
        contactForm {
          sys {
            id
          }
          __typename
        }
      }
    }
  }
`

export default TitleParagraphQuery