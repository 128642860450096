import React, { FC, Fragment } from 'react';
import { Service } from './serive';
import { OurServices } from './types';

type Props = {
  component: {
    ourServices: OurServices
  }
}

export const Services: FC<Props> = ({component}) => {
  const { title, servicesPerLine, servicesPerRowCollection } = component.ourServices;
  const style = { "--rows-basis": `${100 / servicesPerLine}%` } as React.CSSProperties;

  return (
    <section className="our-services page" style={style}>
      <h2 className="section-title"><span className="line">{title}</span></h2>
      <div className="service-container">
        {servicesPerRowCollection.items.map((service, index) => (
          <Service key={index} service={service} /> 
        ))}
      </div>
      </section>
    )
}

export default Services;