import { ListingsProperty } from "../Listings/types";

export type server_property = {
  fields: {
    name: string;
    propertyHandle: string;
    saleType: boolean;
    propertyLocation: {
      fields: {
        location: string;
        region: string;
        country: {
          sys:  {
            id: string;
          }
        };
        continent: string;
      }
    }
    type: string;
    propertyCurrency: string;
    startingFrom: boolean;
    price: number;
    propertySizeSqm: number,
    propertySizeSqftMax: number | undefined;
    propertyBathroomMax: number | undefined;
    propertyBedroomsMax: number | undefined;
    bedrooms: number,
    bathroom: number,
    photos: {
      fields: {
        title: string;
        file: {
          url: string;
        }
      }
    }[];
  }
}

export function mapProperties (item: server_property): ListingsProperty {
  return {
    bathroom: item.fields.bathroom,
    bedrooms: item.fields.bedrooms,
    name: item.fields.name,
    price: item.fields.price,
    startingFrom: item.fields.startingFrom,
    // @ts-ignore
    propertyLocation: item.fields.propertyLocation.fields,
    propertyBedroomsMax: item.fields.propertyBedroomsMax ?? null,
    propertyBathroomMax: item.fields.propertyBathroomMax ?? null, 
    propertySizeSqftMax: item.fields.propertySizeSqftMax ?? null,
    propertyCurrency: item.fields.propertyCurrency,
    propertyHandle: item.fields.propertyHandle,
    propertySizeSqm: item.fields.propertySizeSqm,
    photosCollection: {
      items: item.fields.photos.map(x => ({
        title: x.fields.title,
        url: x.fields.file.url
      }))
    }
  }
}