import React, { Component, Fragment } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Highlight from "./Highlight";

class PropertyHighlights extends Component {
  constructor(props) {
    super(props);

    this.state = {
      component: props.component,
    };
  }

  render() {
    return (
      <div>
        {this.state.component.fields.aboveHighlights.content.map(
          (el, index) => (
            <React.Fragment key={index}>
              {documentToReactComponents(el)}
            </React.Fragment>
          )
        )}

        {this.state.component.fields.propertyTable && (
          <div className="highlight-container">
            {this.state.component.fields.propertyTable.map((el, index) => (
              <Highlight key={index} id={el.sys.id}></Highlight>
            ))}
          </div>
        )}

        {this.state.component.fields.belowHighlights && (
          <Fragment>
            {this.state.component.fields.belowHighlights.content.map(
              (el, index) => (
                <React.Fragment key={index}>
                  {documentToReactComponents(el)}
                </React.Fragment>
              )
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

export default PropertyHighlights;
