import { gql } from "@apollo/client";

export const ImageGalleryQuery = gql`
  query ($id: String!) {
    imageGallery (id: $id) {
      title
      paragraph
      imagesCollection {
        items {
          title
          url
        }
      }
    }
  }
`

export default ImageGalleryQuery;