import { gql } from '@apollo/client';

export const BlogLandingPage = gql`
  query ($id: String!) {
    blogLandingPage(id: $id) {
      blogsCollection {
        items {
          title
          handle
          paragraph
          blogFeaturedImage {
            title
            url
          }
        }
      }
    }
  }
`

export default BlogLandingPage;