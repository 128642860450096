import React, { FC } from "react";
import { GridItem } from "./gridItem";
import { GridContainerType } from "./types";

type Props = {
  component: {
    gridContainer: GridContainerType;
  };
};

export const gridContainer: FC<Props> = ({ component }) => {
  const { title, itemBackground, itemsPerRow, gridItemsCollection } =
    component.gridContainer;
  const items = itemsPerRow || 4;

  const style = {
    "--grid-rows-basis": `${100 / items}%`,
  } as React.CSSProperties;

  return (
    <section className="grid-container" style={style}>
      <h2 className="section-title">
        <span className="line">{title}</span>
      </h2>
      <div className="grid-flex">
        {gridItemsCollection.items.map((item, index) => (
          <GridItem key={index} {...{ ...item, background: itemBackground }} />
        ))}
      </div>
    </section>
  );
};

export default gridContainer;
