import React, { Component, Fragment } from 'react';
import { client } from '../../../../ContentfulContext';
import {Components} from '../..';
import { ComponentLoader } from '../../..';

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


class ArticleLoader extends Component<{id: string, component: any}, {id: string, component: any, url: string, article: any, loaded: boolean}> {
  constructor(props: { id: string; component: any; } | Readonly<{ id: string; component: any; }>) {                      
    super(props);

    this.state = {
      id: props.id,
      component: props.component,
      url:  "/" + window.location.href.substring(window.location.href.lastIndexOf('/') + 1),
      article: [],
      loaded: false
    };
  }

  fetchArticle() {
    client
    .getEntries({content_type: "article", "fields.url[match]": this.state.url})
    .then((entry: any) => this.setState({article: entry.items[0], loaded: true}))
    .catch(err => console.log(err));
  }

  async componentDidUpdate() {
    const currentURL = "/" + window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    if (this.state.url !== currentURL ) {
      await this.setState({url: currentURL, loaded: false, article: []});
      this.fetchArticle();
    }
  }

  async componentDidMount() {
    this.fetchArticle();
  }

  render() {
    return (
      <section>
        {this.state.loaded && (
          <Fragment>
          {this.state.article.fields.components.map((component: any, index: number) => {
            let type = capitalizeFirstLetter(component.sys.contentType.sys.id);

            const mappedComponent = {
              sys: {
                id: component.sys.id
              },
              "__typename": type
            }

            return <ComponentLoader component={mappedComponent} key={index} />

            // const type = component.sys.contentType.sys.id;
            // const ComponentToRender = Components[type].component;
            
            // return <ComponentToRender component={component.fields}/>
          })}
          </Fragment>
        )}
      </section>
    )
  }
}

export default ArticleLoader;