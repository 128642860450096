export interface ComponentsNode {
  component: any;
  query?: any;
}

export let Components: { [key: string]: ComponentsNode } = {
  //Core
  Banner: {
    component: require("./Core/Banner").default,
    query: require("./Core/Banner/queries").default,
  },
  TitleParagraph: {
    component: require("./Core/TitleParagraph").default,
    query: require("./Core/TitleParagraph/queries").default,
  },
  ImageText: {
    component: require("./Core/ImageText").default,
    query: require("./Core/ImageText/queries").default,
  },
  FeaturedProperties: {
    component: require("./Core/FeaturedProperties").default,
    query: require("./Core/FeaturedProperties/queries").default,
  },
  Carousel: {
    component: require("./Core/Carousel").default,
  },
  CustomHtml: {
    component: require("./Core/CustomHTML").default,
  },
  VerticalSpacer: {
    component: require("./Core/VerticalSpacer").default,
  },
  VideoBanner: {
    component: require("./Core/VideoComponent").default,
  },
  Teams: {
    component: require("./Core/Teams").default,
  },
  ContactForm: {
    component: require("./Core/ContactForm").default,
  },
  GridContainer: {
    component: require("./Core/GridContainer").default,
    query: require("./Core/GridContainer/queries").default,
  },
  PressContainer: {
    component: require("./Core/pressContainer").default,
  },
  StaticImage: {
    component: require("./Core/StaticImage.js").default,
  },
  PropertyListings: {
    component: require("./Properties/Listings").default,
    query: require("./Properties/Listings/queries").default,
  },
  Property: {
    component: require("./Properties/Property").default,
  },
  TabbedContent: {
    component: require("./Properties/PropertyToggle").default,
  },
  SubProperties: {
    component: require("./Properties/SubProperties").default,
  },
  ImageGallery: {
    component: require("./Core/ProductGallery").default,
    query: require("./Core/ProductGallery/queries").default,
  },
  PropertyVrTour: { component: require("./Properties/VR").default },
  DestinationListings: {
    component: require("./Destinations/Destination").default,
  },
  Destination: { component: require("./Destinations/Destination").default },
  OurServices: {
    component: require("./Core/OurServices").default,
    query: require("./Core/OurServices/queries").default,
  },
  BlogLandingPage: {
    component: require("./Core/BlogLandingPage").default,
    query: require("./Core/BlogLandingPage/queries").default,
  },
  ArticleLoader: { component: require("./Core/ArticleLoader").default },
};
