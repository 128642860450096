import React, { FC } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { ButtonType } from "../../../types";
import { ContactFormButton } from "./contactFormButton";

export const Button: FC<ButtonType> = ({
  customProps,
  contactForm,
  title,
  type,
  url,
  onClick,
}) => {
  const btnClass = `${type ? "btn-underlined" : "btn"}`;

  if (contactForm)
    return (
      <ContactFormButton
        customProps={customProps}
        contactForm={contactForm}
        title={title}
        type={type}
      />
    );

  if (url === "disabled") {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        className={btnClass}
        onClick={(e) => {
          e.preventDefault();

          onClick && onClick();
        }}
        href="#"
      >
        {title}
      </a>
    );
  }

  if (url?.includes("http")) {
    return (
      <a className={btnClass} href={url}>
        {title}
      </a>
    );
  }

  if (url?.includes("#")) {
    const scrollWithOffset = (el: HTMLElement) => {
      const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
      const yOffset = -125;
      window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    };
    return (
      <HashLink
        className={btnClass}
        to={url}
        scroll={(el) => scrollWithOffset(el)}
      >
        {title}
      </HashLink>
    );
  }

  return (
    <Link className={btnClass} to={url}>
      {title}
    </Link>
  );
};
