import { useEffect, useState } from "react";
import { client } from "../../../../ContentfulContext";
import { PropertyFilters } from "../../../../types";
import { buildPropertiesFilter, mapProperties, server_property } from "../utils";
import { ListingsProperty } from "./types";


export const useFilters = (filters: PropertyFilters, params: {[key: string]: string}): {
  properties: ListingsProperty[]
} => {
  const [entries, setEntries] = useState<ListingsProperty[]>([])

  const getPRoperties = () => {
    client
    .getEntries(buildPropertiesFilter(filters))
    .then(async (entry) => {
      const items = (entry.items as unknown as server_property[])
      const countriesResult = await client.getEntries({"content_type": "helperPropertyCountry"})
      let a = items.map((i) => ({
          ...i,
          fields: {
            ...i.fields,
            propertyLocation: {
              ...i.fields.propertyLocation,
              fields: {
                ...i.fields.propertyLocation.fields,
                // @ts-ignore
                country: countriesResult.items.find(c => c.sys.id === i.fields.propertyLocation.fields.country.sys.id)?.fields.country ?? ""
              }
            }
          }
        }
      ))
      // @ts-ignore
      if (!filters.ids || filters.length === 0 ) {
        if (params.location) {
          a = a.filter(x => x.fields.propertyLocation.fields.country.toLowerCase().replaceAll(" ", "-") === params.location)

        }
      }

      const mappedProperties = a.map(mapProperties)
      setEntries(mappedProperties)
    })
    .catch(err => console.log(err));
  }
  
  useEffect(() => {
    getPRoperties();
  }, [filters])

  useEffect(() => {
    getPRoperties();
  }, [])

  return { properties: entries }
}