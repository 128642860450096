import React, { FC } from "react";
import { Button } from "../../Helper/button";
import { GridItemType } from "./types";

export const GridItem: FC<GridItemType & { background: boolean}> = ({background, title, extract, button, thumbNail}) => (
  <div className={`grid-item ${background && "white"}`}>
    <img src={thumbNail.url} alt={thumbNail.title}></img>
    <div className="grid-misc">
      <h4>{title}</h4>
      <p>{extract}</p>
      {button && <Button {...button} />}
    </div>
  </div>
)