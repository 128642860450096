import React, { FC } from 'react'

type Props = {
  className?: string;
  src: string;
  alt: string;
}

export const Image: FC<Props> = ({className, src, alt}) => (
  <img className={`image ${className}`} src={src} alt={alt}></img>
)