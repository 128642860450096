import { gql } from "@apollo/client";

export const GridContainerQuery = gql`
  query ($id: String!) {
	  gridContainer(id: $id) {
    	title
      itemBackground
      itemsPerRow
      gridItemsCollection {
        items {
        	title
          extract
          button {
            title
            url
            contactForm {
              sys {
                id
              }
              __typename
            }
          }
          handle
          buttonTitle
          thumbNail {
            title
            url
          }
        }
      }
    }
  }
`;

export default GridContainerQuery;