import React, { FC, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { useParams } from "react-router-dom";

import { client } from "../../../../ContentfulContext";

import { PropertyFilters } from "../../../../types";

function mapOptions(option: string) {
  return { label: option, value: option };
}

interface Option {
  readonly label: string;
  readonly value: string | undefined;
}

type FilterOptions = {
  ids: Array<string>;
  list: Option[];
};

const initialFilterOption: FilterOptions = {
  ids: [],
  list: [],
};

export const FilterBar: FC<any> = ({
  applyFilter,
  bannerImage,
  bedroomFilter,
  pageTitle,
  showFilter,
  windowedBanner,
  propertyTypeFilter,
  locationFilterDefault,
  typeFilterDefault,
  showFilters,
  subTitle,
}) => {
  const params = useParams();

  // @ts-ignore
  const [selectedCountry, setSelectedCountry] = useState(params.location);
  const [selectedRegion, setSelectedRegion] = useState(undefined);
  const [selectedLocation, setSelectedLocation] = useState(undefined);

  const [filters, setFilters] = useState<PropertyFilters>({
    rent: window.location.pathname.replace("/properties/", "") === "rent",
    location: locationFilterDefault,
    property_type: typeFilterDefault,
    bedrooms: undefined,
  });

  const [availableFilters, setAvailableFilters] = useState<
    Map<any, any> | undefined
  >(undefined);

  const { countryFilters, locationFilters, regionFilters } = useMemo(() => {
    if (!availableFilters)
      return {
        countryFilters: initialFilterOption,
        locationFilters: initialFilterOption,
        regionFilters: initialFilterOption,
      };

    const countryFilters: FilterOptions = { ids: [], list: [] };
    const regionFilters: FilterOptions = { ids: [], list: [] };
    const locationFilters: FilterOptions = { ids: [], list: [] };

    availableFilters.forEach((value, key) =>
      countryFilters.list.push({ value: key, label: value.label })
    );

    const getRegionAndLocationFilters = (selectedCountriesMap: any) => {
      selectedCountriesMap.regions.forEach((_: unknown, key: string) =>
        regionFilters.list.push({ value: key, label: key })
      );
      if (!selectedRegion || selectedCountriesMap.regions.has(selectedRegion)) {
        if (regionFilters.list.length > 0) {
          if (selectedRegion) {
            const selectedRegionMap =
              selectedCountriesMap.regions.get(selectedRegion);
            regionFilters.ids = selectedRegionMap.ids
              ? selectedRegionMap.ids
              : [];

            selectedRegionMap.locations.forEach((_: unknown, key: string) =>
              locationFilters.list.push({ value: key, label: key })
            );

            if (locationFilters.list.length > 0 && selectedLocation) {
              const selectedLocationMap =
                selectedRegionMap.locations.get(selectedLocation);
              locationFilters.ids = [selectedLocationMap.id] ?? [];
            }
          } else {
            // @ts-ignore
            selectedCountriesMap.regions.forEach((region: unknown) =>
              // @ts-ignore
              region.locations.forEach((location: unknown, key: string) => {
                locationFilters.list.push({ value: key, label: key });

                // @ts-ignore
                if (selectedLocation === key) {
                  // @ts-ignore
                  locationFilters.ids = [location.id];
                }
              })
            );
          }
        }
      }
    };

    if (selectedCountry) {
      const selectedCountriesMap = availableFilters.get(selectedCountry);

      countryFilters.ids = selectedCountriesMap.ids ?? [];
      getRegionAndLocationFilters(selectedCountriesMap);
    } else {
      availableFilters.forEach((country) =>
        getRegionAndLocationFilters(country)
      );
    }

    return {
      countryFilters,
      regionFilters,
      locationFilters: locationFilters,
    };
  }, [availableFilters, selectedCountry, selectedRegion, selectedLocation]);
  const onChangeHandler = (a: string) => (selectedOption: any) =>
    setFilters((e) => ({ ...e, [a]: selectedOption.value }));

  const rent_options = [
    { value: false, label: "Buy" },
    { value: true, label: "Rent" },
  ];
  const property_options = [
    { value: undefined, label: "All Properties" },
    ...propertyTypeFilter.map(mapOptions),
  ];
  const bedroom_options = [
    { value: undefined, label: "All" },
    ...bedroomFilter.map(mapOptions),
  ];

  const style = {
    "--filter-background-url": `url(${bannerImage})`,
  } as React.CSSProperties;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    applyFilter({
      ...filters,
      location: selectedCountry,
      ids: selectedLocation
        ? locationFilters.ids
        : selectedRegion
        ? regionFilters.ids
        : countryFilters.ids,
    });
  };

  const getFilterOptions = async () => {
    const filterOptions = await client.getEntries<{
      country: {
        fields: {
          country: string;
        };
      };
      region: string;
      location: string;
    }>({ content_type: "propertyLocation" });

    const optionsMap = new Map();

    filterOptions.items.forEach((option) => {
      const {
        country: {
          fields: { country },
        },
        region,
        location,
      } = option.fields;
      const countryURL = country.toLowerCase().replaceAll(" ", "-");
      const { id } = option.sys;
      if (optionsMap.has(countryURL)) {
        const countryMap = optionsMap.get(countryURL);
        countryMap.ids.push(id);

        if (countryMap.regions.has(region)) {
          const regionMap = countryMap.regions.get(region);
          regionMap.ids.push(id);

          regionMap.locations.set(location, {
            id,
            location,
          });

          return;
        }

        countryMap.regions.set(region, {
          ids: [id],
          locations: new Map().set(location, {
            id,
            location,
          }),
        });
        return;
      }

      optionsMap.set(countryURL, {
        ids: [id],
        label: country,
        regions: new Map([
          [
            region,
            {
              ids: [id],
              locations: new Map().set(location, {
                id,
                location,
              }),
            },
          ],
        ]),
      });
    });

    setAvailableFilters(optionsMap);
  };

  useEffect(() => {
    getFilterOptions();
  }, []);

  return (
    <React.Fragment>
      <div
        className={`properties-heading ${windowedBanner && "banner-component"}`}
        style={style}
      >
        <div className="vc">
          <h1>{pageTitle}</h1>
          {subTitle && (
            <h3
              className="orchide"
              style={{
                color: "#fff",
                textAlign: "center",
                margin: 0,
                fontSize: "40px !important",
              }}
            >
              {subTitle}
            </h3>
          )}
        </div>
      </div>
      {showFilter && (
        <div
          className={`listing-form-container${showFilters ? " active" : ""}`}
          style={{
            width: "92%",
            maxWidth: "1280px",
            margin: "0 auto",
          }}
        >
          <form className="listing-form">
            <div>
              <p style={{ marginTop: 0, marginBottom: "5px" }}>Rent / Buy</p>
              <Select
                onChange={onChangeHandler("rent")}
                className="input-select-form"
                name="Rent"
                placeholder="Destinations"
                options={rent_options}
                defaultValue={
                  window.location.pathname.replace("/properties/", "") ===
                  "rent"
                    ? rent_options[1]
                    : rent_options[0]
                }
              />
            </div>

            <div>
              <p style={{ marginTop: 0, marginBottom: "5px" }}>Country</p>
              <Select
                className="input-select-form"
                onChange={(e) => {
                  // @ts-ignore
                  setSelectedCountry(e.value);
                  setSelectedRegion(undefined);
                  setSelectedLocation(undefined);
                }}
                name="Country"
                placeholder="Country"
                // @ts-ignore
                options={[
                  { value: undefined, label: "All Countries" },
                  // @ts-ignore
                  ...(countryFilters.list as unknown as Option),
                ]}
                value={
                  selectedCountry
                    ? countryFilters.list.find(
                        (x) => x.value === selectedCountry
                      )
                    : { value: undefined, label: "All Countries" }
                }
              />
            </div>

            <div>
              <p style={{ marginTop: 0, marginBottom: "5px" }}>Region</p>
              <Select
                className="input-select-form"
                onChange={(e) => {
                  // @ts-ignore
                  setSelectedRegion(e.value);
                  setSelectedLocation(undefined);
                }}
                name="Region"
                placeholder="Region"
                // @ts-ignore
                options={[
                  { value: undefined, label: "All Regions" },
                  // @ts-ignore
                  ...(regionFilters.list as unknown as Option),
                ]}
                value={
                  selectedRegion
                    ? { value: selectedRegion, label: selectedRegion }
                    : { value: undefined, label: "All Regions" }
                }
              />
            </div>

            <div>
              <p style={{ marginTop: 0, marginBottom: "5px" }}>Location</p>
              {/* @ts-ignore */}
              <Select
                className="input-select-form"
                // @ts-ignore
                onChange={(e) => setSelectedLocation(e.value)}
                name="Location"
                placeholder="Location"
                options={[
                  { value: undefined, label: "All Locations" },
                  // @ts-ignore
                  ...(locationFilters.list as unknown as Option),
                ]}
                value={
                  selectedLocation
                    ? { value: selectedLocation, label: selectedLocation }
                    : { value: undefined, label: "All Locations" }
                }
              />
            </div>
            <div>
              <p style={{ marginTop: 0, marginBottom: "5px" }}>Type</p>
              <Select
                onChange={onChangeHandler("property_type")}
                className="input-select-form"
                name="Property type"
                placeholder="Property type"
                options={property_options}
              />
            </div>
            <div>
              <p style={{ marginTop: 0, marginBottom: "5px" }}>Bedrooms</p>
              <Select
                onChange={onChangeHandler("bedrooms")}
                className="input-select-form"
                name="Bedrooms"
                placeholder="Bedrooms"
                options={bedroom_options}
              />
            </div>
            <button onClick={handleSubmit} className="btn">
              Search
            </button>
          </form>
        </div>
      )}
    </React.Fragment>
  );
};

export default FilterBar;
