import React, { useState, useEffect } from "react";
import { client } from "../Store/client";
import { client as ContentfulClient } from "../ContentfulContext";
import { ComponentType } from "../types";
import { Components } from "./Components";

interface ComponentLoaderProps {
  component: ComponentType;
  customProps?: any;
}

const getComponentData = async (id: string, query: any) => {
  try {
    return await client
      .query({ query: query, variables: { id } })
      .then((result: any) => result.data);
  } catch (error) {
    console.error(error);
  }
};

export function ComponentLoader({
  component,
  customProps,
}: ComponentLoaderProps) {
  const componentIndex = Components[component.__typename];
  const [componentState, setComponentState] = useState<any>(null);

  useEffect(() => {
    if (componentIndex?.query) {
      getComponentData(component.sys.id, componentIndex?.query).then((data) =>
        setComponentState(data)
      );
    } else {
      ContentfulClient.getEntry(component.sys.id).then((entry: any) => {
        setComponentState(entry.fields);
      });
    }
  }, [componentIndex, component]);

  const ComponentToRender = componentIndex?.component;

  return (
    <>
      {ComponentToRender && componentState && (
        <ComponentToRender component={{ ...componentState }} {...customProps} />
      )}
    </>
  );
}
