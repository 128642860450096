import React, { FC, Fragment, useEffect, useState } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { client } from './ContentfulContext';
import './Styles/components.sass';
import Nav from './View/Core/Navigation/Navigation';
import Footer from './View/Core/Footer/Footer';
import CookieBanner from './View/Core/CookieBanner';
import e404 from './View/Core/e404';
import {PageLoader} from "./pageLoader/index"
import { Page } from "./types";
import Consultation from './components/Components/Consultation';

function mapPagesResponse (entry: any): Page {
  return {
    "id": entry.sys.id,
    "title": entry.fields.title,
    "url": entry.fields.url,
    "navColour": entry.fields.whiteNavigation,
    "meta_description": entry.fields.meta_description,
    "landingPage": entry.fields.landingPage
  }
}

const AppRouter: FC = (props: any) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [siteMap, setSiteMap] = useState<Page[]>([]);
  const [hideBurger, setHideBurger] = useState<boolean>(false);
  const [showCookies, setShowCookies] = useState<boolean>(true);
  const [navColour, setNavColour] = useState<boolean>(true);


  const fetchPages = () => {
    client
    .getEntries({"content_type": "page", "select": ["fields.title", "fields.url", "fields.whiteNavigation", "fields.meta_description", "fields.landingPage"]})
    .then(entries => {
      let site_map: Page[] = entries.items.map(mapPagesResponse)
       setSiteMap(site_map)
       setLoaded(true)
    })
    .catch(err => console.log(err));
  }

  useEffect(() => {
    fetchPages();
    
    if (localStorage.getItem("cookies") === "accepted") {
      setShowCookies(false);
    }
  }, [])

  const AuthSwitch = () => (
    <Switch>
      {siteMap.map((page: Page, index: number) => (
        <Route exact key={index} path={page.url} component={() => <PageLoader page={page} setNavColour={setNavColour} setHideBurger={setHideBurger} />} />
        // <Route exact path={url} render={() => <ComponentLoader page={landingPage} title={title} desc={meta_description} id={id} nav_colour={navColour} navColour={this.updateNavColour} hideBurger={this.hideBurger}></ComponentLoader>}></Route>
      ))}
      <Route component={e404} /> 
    </Switch>
  )

    return (
      <Fragment>
      {loaded && (
        <div className="page-load">
          <Nav colour={navColour} hideBurger={hideBurger} pathname={props.location.pathname} />
          <AuthSwitch />
          {!hideBurger && (
            <React.Fragment>
              <Consultation />
              <Footer />
            </React.Fragment>
          )}
          {showCookies && (
            <CookieBanner />
          )}
        </div>
      )}
      </Fragment>
    )
}

export default withRouter(AppRouter);
