import React from 'react';
import { TitleSize, TextCentered } from './models'

interface TextProps {
  title?: string,
  titleSize?: TitleSize,
  textCentered?: TextCentered,
  column?: boolean,
  paragraph?: string,
  rows?: number,
  accent?: boolean
  textColour?: string
  backgroundColour?: string
}

export const Text = ({title, titleSize = 'Heading 1', textCentered, column, paragraph, backgroundColour, textColour, accent, rows}: TextProps) => (
  <>
  <div className={`paragraph-container${ textCentered ? ` ${textCentered}` : '' }`}>
    <div className={`content-animation${rows ? " capped" : ""}`} style={{
      lineClamp: rows ? rows : 10000,
      WebkitLineClamp: rows ? rows : 10000
    }}>
      {title && <Title title={title || ""} titleSize={titleSize} accent={accent} textColour={textColour} backgroundColour={backgroundColour} />} 
      {paragraph && <Paragraph paragraph={paragraph || ""} column={column || false} accent={accent} /> }
    </div>
    
  </div>
  </>
)

const Title = ({title, titleSize = "Heading 1", accent, textColour, backgroundColour}: TextProps) => (
  <>
    {{
      "Heading 1": <h1 className={`heading-1${accent ? " accent" : " primary"}`}>{textColour ? <span style={{color: textColour}}>{ title }</span> : title }</h1>,
      "Heading 2": <h1 className={`heading-2${accent ? " accent" : " primary"}`}>{textColour ? <span style={{color: textColour}}>{ title }</span> : title}</h1>,
      "Heading 3": <h2 className={`heading-3${accent ? " accent" : " primary"}`}>{ title }</h2>,
      "Heading 4": <p className={`heading-4${accent ? " accent" : " primary"}`} style={{margin: 0}}>
        {textColour ? 
          <span style={{color: textColour, backgroundColor: backgroundColour ? backgroundColour : "none"}}>
            { title }
          </span> : 
          <span style={{backgroundColor: backgroundColour ? backgroundColour : "none"}}>
            { title }
          </span>
        }</p>,
      "Heading 5": <p className={`heading-5${accent ? " accent" : " primary"}`}>{ title }</p>,
      "Heading 6": <p className={`heading-6${accent ? " accent" : " primary"}`}>{textColour ? <span style={{color: textColour}}>{ title }</span> : title }</p>,
      "Heading 7": <p className={`heading-7${accent ? " accent" : " primary"}`}><span>{ title }</span></p>
    }[ titleSize ]}
  </>
)

const Paragraph = ({paragraph, column, accent}: TextProps) => (
  <p className={`paragraph${accent ? " accent" : " primary"}${column ? " two-column" : ""}`} style={{margin: 0}}>{ paragraph }</p>
)