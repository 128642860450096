import React, { FC, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import TextTruncate from "react-text-truncate";

import { formatMoney } from "../../Properties/utils";
import { ComponentLoader } from "../../..";
import { FeaturedProperty } from "./types";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import { ArrowLeft, ArrowRight } from "../../../../Styles/icons";
import { Button } from "../../Helper/button";

const options = {
  root: null,
  rootMargin: "0px",
  threshold: [0.1, 0.5, 1.0],
};

type Props = {
  component: {
    featuredProperties: FeaturedProperty;
  };
};

const FeaturedProperties: FC<Props> = ({ component }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [contactPropertyName, setContactPropertyName] = useState<string>();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.intersectionRatio > 0.1 && !visible) {
        setVisible(true);
      }
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [visible]);

  const {
    title,
    buttonTitle,
    buttonUrl,
    propertiesCollection: { items: properties },
    contactForm,
  } = component.featuredProperties;

  return (
    <div className="featured-properties page" ref={ref}>
      {title && <h2 className="title orchide">{title}</h2>}

      <div className="properties">
        {properties.map((property, index) => {
          let {
            name,
            price,
            bedrooms,
            bathroom,
            propertySizeSqm,
            propertyBedroomsMax,
            propertyBathroomMax,
            propertySizeSqftMax,
            description,
            startingFrom,
            propertyCurrency,
            photosCollection,
            propertyHandle,
          } = property;
          const propertyLink = `/property/${propertyHandle}`;

          if (propertyBedroomsMax && bedrooms !== propertyBedroomsMax)
            bedrooms += " - " + propertyBedroomsMax;
          if (propertyBathroomMax) bathroom += " - " + propertyBathroomMax;
          if (propertySizeSqftMax)
            propertySizeSqm += " - " + propertySizeSqftMax;
          const priceLabel = `${
            startingFrom ? "From" : ""
          } ${propertyCurrency}${formatMoney(price)}`;

          const PropertyCard = ({ link }: { link: string }) => (
            <div>
              {/* <img
                alt={photosCollection.items[0].fileName}
                src={photosCollection.items[0].url}
              /> */}

              <CarouselProvider
                className="featuredPropertyCarousel"
                infinite
                naturalSlideWidth={100}
                naturalSlideHeight={50}
                totalSlides={photosCollection.items.length}
                // visibleSlides={this.visibleSlides}
              >
                <Slider>
                  {photosCollection.items.map((image, key) => {
                    const { url, fileName } = image;
                    return (
                      // @ts-ignore
                      <Slide className="slider-image" key={key}>
                        <img alt={fileName} src={url}></img>
                      </Slide>
                    );
                  })}
                </Slider>
                <ButtonBack className="arrow-container prev">
                  <ArrowLeft></ArrowLeft>
                </ButtonBack>
                <ButtonNext className="arrow-container next">
                  <ArrowRight></ArrowRight>
                </ButtonNext>
              </CarouselProvider>

              <>
                <h2>{name}</h2>
                <h3>
                  {bedrooms} Beds | {bathroom} Baths | {propertySizeSqm} Sqft
                </h3>
                <p>
                  <TextTruncate
                    line={2}
                    element="span"
                    truncateText="…"
                    text={description}
                  />
                </p>
                <p className="price">{priceLabel}</p>
                <Button
                  customProps={contactPropertyName}
                  title={contactForm ? "Contact Us" : "Discover More"}
                  type={true}
                  url={contactForm ? "disabled" : propertyLink}
                  contactForm={undefined}
                  onClick={() => setContactPropertyName(name)}
                />
              </>
            </div>
          );

          return (
            <div key={index} className={`item${visible ? " visible" : ""}`}>
              <PropertyCard link={propertyLink} />
            </div>
          );
        })}
      </div>
      {contactPropertyName && contactForm && (
        <div
          style={{
            position: "fixed",
            height: "calc(100vh - 20px)",
            width: "calc(100vw - 20px)",
            left: "0",
            top: "0",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <ComponentLoader
            component={{
              ...contactForm,
              __typename: "ContactForm",
            }}
            customProps={{
              propertyName: contactPropertyName,
            }}
          />
          <div
            onClick={() => setContactPropertyName(undefined)}
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              backgroundColor: "rgba(0,0,0,0.5",
            }}
          ></div>
        </div>
      )}
      {buttonTitle && (
        <Link className="btn" to={buttonUrl}>
          {buttonTitle}
        </Link>
      )}
    </div>
  );
};

export default FeaturedProperties;
