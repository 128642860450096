import React, { useEffect, useRef, useState } from "react";
export const PropertyNavigation = (props) => {
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const components = props.components;
  let navHeight = document.getElementById("navigation-contianer").clientHeight;
  const handleScroll = () => {
    if (ref.current) {
      navHeight = document.getElementById("navigation-contianer").clientHeight;
      setSticky(ref.current.getBoundingClientRect().top <= navHeight);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  });

  const scrollWithOffset = (linkTo) => {
    const el = document.getElementById(linkTo);

    if (el) {
      const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
      const yOffset = -150;
      window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    }
  };

  return (
    <div className="property-navigation" ref={ref}>
      <div className={`property-tabs${isSticky ? " sticky" : ""}`}>
        <div id="tabbed-scrolled" className="tabs-container">
          {components.map((component, index) => {
            let linkTo = "";
            let title = "";

            if (component.fields.title) {
              linkTo = component.fields.title.trim().replaceAll(" ", "-");
              title = component.fields.title;
            }

            if (component.fields.tite) {
              linkTo = component.fields.tite.trim().replaceAll(" ", "-");
              title = component.fields.tite;
            }

            return (
              <div className="tab-link active" key={index}>
                <a
                  href={`#${linkTo}`}
                  onClick={(e) => {
                    e.preventDefault();
                    scrollWithOffset(linkTo);
                  }}
                >
                  <p>{title}</p>
                </a>
              </div>
            );
          })}

          <a
            href={`#contact-us`}
            onClick={(e) => {
              e.preventDefault();
              scrollWithOffset("contact-us");
            }}
            className="btn property-btn"
          >
            Request A Brochure
          </a>
        </div>
      </div>
    </div>
  );
};
