import { gql } from "@apollo/client"

const ImageTextQuery = gql`
  query ($id: String!) {
    imageText(id: $id) {
      title
      font
      paragraph
      button {
        title
        type
        url
        contactForm {
          sys {
            id
          }
          __typename
        }
      }
      buttonTitle
      buttonUrl
      image {
        title
        url
      }
      imageOnTheLeft
      ratio
      textLarger
    }
  }
`

export default ImageTextQuery