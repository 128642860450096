import React, { FC } from "react";
import { Responsive } from "../../Responsive";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { ImageGallery } from "./types";

type Props = {
  component: {
    imageGallery: ImageGallery;
  };
};

const ProductGallery: FC<Props> = ({ component }) => {
  const { title, paragraph, imagesCollection } = component.imageGallery;

  return (
    <section id={title?.replace(" ", "-")}>
      <div className="page-container">
        <h2 className="section-title orchide">
          <span className="line">{title}</span>
        </h2>
        <p>{paragraph}</p>
      </div>
      <Responsive displayIn={["Laptop"]}>
        <div className="product-gallery-component">
          {imagesCollection.items.map((image, index) => {
            const { url, title } = image;
            return (
              <div className="image-container" key={index}>
                <img alt={title} src={url}></img>
              </div>
            );
          })}
        </div>
      </Responsive>
      <Responsive displayIn={["Mobile"]}>
        <CarouselProvider
          className="carousel"
          infinite
          naturalSlideWidth={100}
          naturalSlideHeight={100}
          totalSlides={imagesCollection.items.length}
        >
          <Slider>
            {imagesCollection.items.map((image, key) => {
              const { url, title } = image;
              return (
                // @ts-ignore
                <Slide className="slider-image" key={key}>
                  <img alt={title} src={url}></img>
                </Slide>
              );
            })}
          </Slider>
        </CarouselProvider>
      </Responsive>
    </section>
  );
};

export default ProductGallery;
