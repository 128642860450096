import React, { FC, useState } from "react";
import { ComponentLoader } from "../../";
import { ComponentType } from "../../../types";

type Props = {
  contactForm: ComponentType;
  customProps?: any;
  title: string;
  type: boolean;
};

export const ContactFormButton: FC<Props> = ({
  customProps,
  contactForm,
  title,
  type,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const btnClass = `${type ? "btn-underlined" : "btn"}`;

  return (
    <>
      <a
        className={btnClass}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setVisible(true);
        }}
      >
        {title}
      </a>
      {visible && (
        <div
          style={{
            position: "fixed",
            height: "calc(100vh - 20px)",
            width: "calc(100vw - 20px)",
            left: "0",
            top: "0",
            zIndex: 100000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <ComponentLoader component={contactForm} customProps={customProps} />
          <div
            onClick={() => setVisible(false)}
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              backgroundColor: "rgba(0,0,0,0.5",
            }}
          ></div>
        </div>
      )}
    </>
  );
};
