import React, { FC } from 'react';
import CustomLink from '../CustomLink';

import { TitleParagraphProps} from "./types"
import { Button } from '../../Helper/button';

export const TitleParagraph: FC<{component: { titleParagraph: TitleParagraphProps}}> = ({component}) => {
  const {title, font, hideLine, paragraph, buttonTitle, buttonUrl, button} = component.titleParagraph;

  return (
  <div className="titlePara-component">
  <h2 className={`section-title${font ? "" : " orchide"}`}>{!hideLine ? <span className="line">{title}</span> : title}</h2>
  <p>{paragraph}</p>
  {buttonTitle && !button && (
    <div style={{marginTop: "15px"}}>
    <CustomLink className="btn" to={buttonUrl}>{buttonTitle}</CustomLink>
    </div>
  )}
  {button && (
    <div style={{ marginTop: "25px"}}>
      <Button {...button} />
    </div>
  )}
</div>
)
  }

export default TitleParagraph;