import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import Banner from '../Banner';
import BlogList from '../BlogList';
import { Link } from 'react-router-dom';
import { BlogLandingPageType } from "./types"
import { BannerType } from '../Banner/types';

type Props = {
  component: {
    blogLandingPage: BlogLandingPageType;
  }
}

 // this.state = {
    //   url: window.location.pathname.replace("/montague-post", ""),
    //   component: props.component,
    //   blogs: [],
    //   articles: [],
    //   activeBlog: null,
    // };
  

  // async fetchBlogs() {
  //   let id = "";
    
  //   const promise = await this.state.component.blogs.map((blog, key) => {
  //     if (key === 0) return id = id + blog.sys.id
  //     return id = id + `,${blog.sys.id}`
  //   });
  //   await Promise.all(promise);
  
  //   client
  //     .getEntries({"sys.id[in]": id})
  //     .then(entry => {
  //       if (this.state.url === "" || this.state.url ==="/") {
  //         const activeBlog = entry.items.find(o => o.sys.id === this.state.component.blogs[0].sys.id);
  //         this.setState({blogs: entry.items, activeBlog: activeBlog, loaded: true})
  //       } else {
  //         const activeBlog = entry.items.find(o => o.fields.handle === this.state.url);
  //         const index = this.state.component.blogs.findIndex(x => x.sys.id === activeBlog.sys.id)
  //         console.log(index);
  //         this.setState({blogs: entry.items, activeBlog: activeBlog, initialSlide: index, loaded: true})
  //       }
        
  //     })
  //     .catch(err => console.log(err));
  // }

const settings = {
  dots: false,
  infinite: false,
  fade: true,
  speed: 500,
  slidesToScroll: 1,
  slidesToShow: 1,
  draggable: false,
  initialSlide: 0
};

export const BlogLandingPage: FC<Props> = ({component}) => {
  const [activeBlog, setActiveBlog] = useState<any>(undefined)

  let slider: React.LegacyRef<Slider> | undefined;
  
  
  // useEffect(() => {
  //   const url = window.location.pathname.replace("/montague-post", "");
  //   const active = component.blogLandingPage.blogsCollection.items.find()
  // }, [])


  const { blogsCollection: { items: blogs } } = component.blogLandingPage
    return (
      <section id="blog-listing-page">
        <div className="blog-header">
          <Slider {...settings} ref={slider}>
            {blogs.map((blog, index) => {
              const banner: BannerType = {
                title: blog.title,
                image: blog.blogFeaturedImage,
                buttonCollection: {
                  items: []
                }
              }
              
              return (
                <Banner key={index} component={{ banner }} />
              )
            })}
          </Slider>

          
          <div className="banner-component property-tabbed-content">
            <div className="property-toggle-container">
              <div className="toggle-headings">
              {blogs.map((blog, key) => {
                  const url = "/montague-post" + blog.handle;
                  return (
                  // <Link to={url}>
                        <p onClick={() => {
                          if(slider) (slider as any).slickGoTo(key);
                          setActiveBlog(blog)
                        }}>{blog.title}</p>
                      // </Link>
                    )
                })}
              </div>
            </div>
          </div> 

        </div>

        {/* <div className="blog-articles page">
          {this.state.articles && this.state.activeBlog ? (
            <BlogList blog={this.state.activeBlog.sys.id} title={this.state.activeBlog.fields.title} paragraph={this.state.activeBlog.fields.paragraph} articles={this.state.articles}></BlogList>
          ) : null}
        </div> */}

      </section>
    )
}

export default BlogLandingPage;