import React, { useState, useEffect } from 'react'
import { client } from '../Store/client'
import { ComponentLoader } from '../components/index'
import { ComponentType } from './models'
import { QUERY_PAGE_COMPONENTS_BY_ID } from './queries'
import {Helmet} from "react-helmet";
import { Page } from '../types'; 

interface PageLoaderProps {
  page: Page
  setHideBurger: React.Dispatch<React.SetStateAction<boolean>>
  setNavColour: React.Dispatch<React.SetStateAction<boolean>>
}

const getPageComponents = async(pageId: string) => {
  try {
    return client.query({ query: QUERY_PAGE_COMPONENTS_BY_ID, variables: { id: pageId } }).then(result => result.data)
  } catch(err) {
    console.error(err)
  }
}

function PageLoaderEvent({page, setHideBurger, setNavColour}: PageLoaderProps) {
  const [pageComponents, setPageComponents] = useState<ComponentType[]>([])

  useEffect(() => {
    getPageComponents(page.id)
    .then((data) => setPageComponents(data.page.componentsCollection.items as ComponentType[]))

    setHideBurger(!!(page.landingPage));
    setNavColour(!!(page.navColour))
  }, [page, setPageComponents, setNavColour, setHideBurger])

  return (
    <div className="componets">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{page.title}</title>
        {page.meta_description && <meta name="description" content={page.meta_description} /> }          
      </Helmet>
      {pageComponents.map((component: ComponentType, key: number) => (
        <ComponentLoader key={key} component={component}  />
      ))}
    </div>
  )
}

export const PageLoader = React.memo(PageLoaderEvent)