import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";

import "../../../../Styles/listings.sass";
import { Link } from "react-router-dom";
import FilterBar from "../FilterBar";
import { formatMoney } from "../utils";
import { ListingsProperty, ListingsType } from "./types";
import { useFilters } from "./useFilters";
import { PropertyFilters } from "../../../../types";

type Props = {
  component: {
    propertyListings: ListingsType;
  };
};

export const Listings: FC<Props> = ({ component }) => {
  const params = useParams();
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState<PropertyFilters>({
    rent: window.location.pathname.replace("/properties/", "") === "rent",
    location: undefined,
    property_type: undefined,
    bedrooms: undefined,
  });
  const [state, setState] = useState({
    id: window.location.pathname.replace("/properties/", ""),
    intervalId: "" as any,
    locationFilterDefault: undefined,
    typeFilterDefault: "",
    currentCount: 0,
    target: "" as any,
    count: 0,
    cardSize: false,
  });

  const [hightToLow, setHighToLow] = useState(false);

  const { properties } = useFilters(filters, params);

  const [orderedProperties, setOrderedProperties] = useState(() =>
    properties.sort((a, b) =>
      hightToLow ? b.price - a.price : a.price - b.price
    )
  );

  useEffect(() => {
    const sort = properties.sort((a, b) =>
      hightToLow ? b.price - a.price : a.price - b.price
    );
    setOrderedProperties(sort);
  }, [properties, hightToLow]);

  const onMouseLeaveHandle = (e: any) => {
    if (state.target === "") return;
    let target: any = state.target;
    const count = target.childNodes.length;

    if (count > 1) {
      clearInterval(state.intervalId);
      target.querySelector(".active").className = "";
      target.childNodes[0].className = "active";
      setState((e) => ({ ...e, currentCount: 0, count: 0 }));

      for (
        let i = 0;
        i < (state.target as any).previousElementSibling.childNodes.length;
        i++
      ) {
        target.previousElementSibling.childNodes[i].className = "thumb";
      }
    }
  };

  const onMouseOverHandle = (e: any) => {
    let target: any;

    if (e.target.classList.contains("thumbnails")) {
      target = e.target.nextElementSibling;
    } else {
      target = e.target.closest(".image-container");
    }

    let count = target.childNodes.length;

    if (count > 1) {
      const thumbs = target.closest(".image-container").previousElementSibling;
      thumbs.childNodes[0].className += " active";

      var intervalId = setInterval(timer, 1000);
      setState((e) => ({ ...e, intervalId, currentCount: count, target }));
    }
  };

  const timer = () => {
    let target: any = state.target;
    var newCount = state.currentCount - 1;
    const thumbs = target.previousElementSibling;

    if (newCount >= 0) {
      var count = state.count + 1;
      if (newCount > 0) {
        thumbs.childNodes[count].className += " active";
        target.childNodes[count].className = "active";
        target.childNodes[count - 1].className = "";
      }

      setState((e) => ({ ...e, currentCount: newCount, count: count }));
    } else {
      clearInterval(state.intervalId);
      setState((e) => ({ ...e, currentCount: 0, count: 0 }));
    }
  };

  const setCardSizeSmall = () => setState((e) => ({ ...e, cardSize: false }));

  const setCardSizeLarge = () => setState((e) => ({ ...e, cardSize: true }));

  const {
    bannerImage,
    pageTitle,
    showFilter,
    windowedBanner,
    bedroomFilter,
    propertyLocationFiltersCollection,
    propertyTypeFilter,
    subTitle,
  } = component.propertyListings;

  return (
    <div>
      <FilterBar
        bannerImage={bannerImage.url}
        typeFilterDefault={state.typeFilterDefault}
        locationFilterDefault={state.locationFilterDefault}
        applyFilter={setFilters}
        pageTitle={pageTitle}
        showFilter={showFilter}
        windowedBanner={windowedBanner}
        bedroomFilter={bedroomFilter}
        locationFilters={propertyLocationFiltersCollection.items}
        propertyTypeFilter={propertyTypeFilter}
        showFilters={showFilters}
        subTitle={subTitle}
      />

      <div className="view-config">
        <div className="results">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              gap: "20px",
              fontSize: "12px",
            }}
          >
            {/* <p>Results: {properties.length}</p> */}

            <Select
              isSearchable={false}
              onChange={(e) => {
                if (e) setHighToLow(e?.value);
              }}
              className="input-select-form bedrooms"
              defaultValue={{ value: false, label: "Low to High" }}
              options={[
                { value: false, label: "Low to High" },
                { value: true, label: "High to Low" },
              ]}
            />
          </div>
        </div>
        <div className="grids desktop">
          <div className="grid-large" onClick={setCardSizeSmall}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="grid-small" onClick={setCardSizeLarge}>
            <span></span>
          </div>
        </div>
        <div className="grids mobile">
          <p onClick={() => setShowFilters(!showFilters)}>Filters</p>
        </div>
      </div>

      <div
        className={`${
          state.cardSize ? "properties-list large" : "properties-list"
        }`}
      >
        {orderedProperties.map((property: ListingsProperty, index: number) => {
          const {
            bathroom,
            bedrooms,
            name,
            propertyLocation,
            photosCollection: { items: photos },
            propertyHandle,
            propertyCurrency,
            propertyBathroomMax,
            propertyBedroomsMax,
            propertySizeSqm,
            propertySizeSqftMax,
            price,
            startingFrom,
          } = property;
          const propertyLink = `/property/${propertyHandle}`;

          const bedroomsLabel =
            bedrooms !== propertyBedroomsMax && propertyBedroomsMax !== null
              ? `${bedrooms} - ${propertyBedroomsMax}`
              : bedrooms;
          const bathroomsLabel =
            bathroom !== propertyBathroomMax && propertyBathroomMax !== null
              ? `${bathroom} - ${propertyBathroomMax}`
              : bathroom;
          const sqftLabel =
            propertySizeSqm !== propertySizeSqftMax &&
            propertySizeSqftMax !== null
              ? `${propertySizeSqm} - ${propertySizeSqftMax}`
              : propertySizeSqm;
          const priceLabel = `${
            startingFrom ? "From" : ""
          } ${propertyCurrency}${formatMoney(price)}`;

          return (
            <div key={index} className="listing-card">
              <div className="content">
                <Link className="" to={propertyLink}>
                  <div
                    className="image"
                    onMouseEnter={onMouseOverHandle}
                    onMouseLeave={onMouseLeaveHandle}
                  >
                    <div className="thumbnails">
                      {photos
                        .slice(0, 4)
                        .reverse()
                        .map(
                          (phone: any, index: number) =>
                            photos.length > 1 && (
                              <div className="thumb" key={index}>
                                <div className="fill"></div>
                              </div>
                            )
                        )}
                    </div>
                    <div className="image-container">
                      {photos.slice(0, 4).map((photo: any, key: number) => (
                        <img
                          key={key}
                          className={`${key === 0 && "active"}`}
                          alt={photo.title}
                          src={photo.url}
                        ></img>
                      ))}
                    </div>
                  </div>
                  {/* style={{ backgroundImage: `url('${property.fields.photos[0].fields.file.url}')` }}></div> */}
                </Link>
                <div className="details">
                  <Link className="" to={propertyLink}>
                    <h3 className="subtitle">{`${propertyLocation.location}, ${
                      propertyLocation.region ?? propertyLocation.country
                    }`}</h3>
                    <h2 className="title">{name}</h2>
                    <p className="price">
                      {bedroomsLabel} Beds | {bathroomsLabel} Baths |{" "}
                      {sqftLabel} Sqft
                    </p>
                    <p className="price">
                      {priceLabel}
                      {filters.rent && "pcm"}
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Listings;
