import { gql } from '@apollo/client'

export const QUERY_PAGE_COMPONENTS_BY_ID = gql`
  query ($id: String!) {
    page(id: $id) {
      componentsCollection {
        items {
          sys {
            id
          }
        }
      }
    }
  }
`