import { PropertyFilters } from "../../../../types";

export function buildPropertiesFilter(filters: PropertyFilters) {
  const select = [
    "fields.name",
    "fields.propertyHandle",
    "fields.propertyLocation",
    "fields.saleType",
    "fields.type",
    "fields.propertyCurrency",
    "fields.startingFrom",
    "fields.price",
    "fields.propertySizeSqm",
    "fields.propertySizeSqftMax",
    "fields.propertyBathroomMax",
    "fields.propertyBedroomsMax",
    "fields.bedrooms",
    "fields.bathroom",
    "fields.photos",
  ]
  let compiledFilters: {[key: string]: boolean | string | string[]} = {"content_type": "properties", "select": select};

  if (filters.rent) compiledFilters["fields.saleType"] = filters.rent
  if (!filters.rent) compiledFilters["fields.saleType[ne]"] = "true"
  // @ts-ignore
  if (filters.ids) { 
    // @ts-ignore
    compiledFilters["fields.propertyLocation.sys.id[in]"] = filters.ids.toString();
    // compiledFilters["fields.propertyLocation.sys.contentType.sys.id"] = "propertyLocation"
  }
  if (filters.property_type) { compiledFilters["fields.type"] = filters.property_type }
  if (filters.bedrooms) {
    const filter = {"fields.bedrooms[lte]": filters.bedrooms, "fields.propertyBedroomsMax[gte]": filters.bedrooms}
    compiledFilters = {...compiledFilters, ...filter};
  }
  return compiledFilters;
}