import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Router from './Router';
import 'normalize.css';
import { ApolloProvider } from '@apollo/client';

import { client } from './Store/client';

const App = () => (
  <BrowserRouter>
    <ApolloProvider client={client}>
      <Router />
    </ApolloProvider>
  </BrowserRouter>
)

export default App;
