import React, { FC } from "react"
import { ButtonType } from "../../../types"
import { Button } from "./button"

type Props = {
  collection: {
    items: ButtonType[]
  } 
}

export const ButtonCollection: FC<Props> = ({collection: { items }}) => {
  if (items.length === 0) return null

  return (
    <div style={{display: "flex", gap: "10px", justifyContent: "center"}}>
      {items.map((button, index) => (
        <Button {...button} key={index} />
      ))}
    </div>
  )
}