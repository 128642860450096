import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import TextTruncate from 'react-text-truncate';
import { ServiceType } from './types';
import { Button } from '../../Helper/button';

export const Service: FC<{service: ServiceType}> = ({service}) => {
  const {title, handle, extract, button, buttonTitle, thumbNail} = service;

  return (
    <div className="service-item">
      <Link to={handle}>
        <img src={thumbNail.url} alt={thumbNail.title}></img>
      </Link>
      <div className="service-misc">
        <h2>{title}</h2>
        <p><TextTruncate line={3} element="span" truncateText="…" text={extract}/></p>
        {button && <Button {...button} /> }
        {buttonTitle && <Link className="btn" to={handle}>{buttonTitle}</Link> }
      </div>
    </div>
  )
}