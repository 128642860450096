import React, { FC, useEffect, useState, useRef } from "react";
import { Button } from "../../Helper/button";
import CustomLink from "../CustomLink";
import { ImageTextType } from "./types";

type Props = {
  component: {
    imageText: ImageTextType;
  };
};

const ImageText: FC<Props> = ({ component }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: [0.1, 0.5, 1.0],
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.intersectionRatio > 0.2 && !visible) {
        setVisible(true);
      }
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [ref]);

  const {
    title,
    paragraph,
    button,
    buttonTitle,
    buttonUrl,
    font,
    imageOnTheLeft,
    ratio,
    image,
    textLarger,
  } = component.imageText;

  return (
    <div
      id={title.trim().replaceAll(" ", "-")}
      ref={ref}
      className={`imageText-component page ${imageOnTheLeft && "flip"} ${
        visible && "visible "
      }${ratio && " half"}${textLarger ? " larger-text" : ""}`}
    >
      <div className="image">
        <img alt={image.title} src={image.url}></img>
      </div>
      <div className="content">
        <div className="padding">
          {title && (
            <h2 className={`title${font ? "" : " orchide"}`}>{title}</h2>
          )}
          {title && <div className="under-line"></div>}
          {paragraph && <p className="paragraph">{paragraph}</p>}
          {button && <Button {...button} />}
          {buttonTitle && (
            <CustomLink to={buttonUrl} className="btn">
              {buttonTitle}
            </CustomLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageText;
