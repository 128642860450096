import { gql } from "@apollo/client";

export const BannerQuery = gql`
  query ($id: String!) {
    banner(id: $id) {
    	title
    	subTitle
    	subSubTitle
      buttonCollection {
        items {
          title
          type
          url
          contactForm {
            sys {
              id
            }
            __typename
          }
        }
      }
    	buttonTitle
    	buttonLink
    	image {
      	title
      	url
    	}
    	fullBleed
    	fonts
    	boltCta
      video {
        title
        url
      }
    }
  }
`
export default BannerQuery