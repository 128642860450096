import { gql } from "@apollo/client";

const FeaturedPropertiesQuery = gql`
  query ($id: String!) {
    featuredProperties(id: $id) {
      title
      buttonTitle
      buttonUrl
      propertiesCollection {
        items {
          name
          propertyHandle
          description
          photosCollection {
            items {
              fileName
              url
            }
          }
          bedrooms
          bathroom
          propertySizeSqm
          propertyBedroomsMax
          propertyBathroomMax
          propertySizeSqftMax
          price
          propertyCurrency
          startingFrom
        }
      }
      contactForm {
        sys {
          id
        }
      }
    }
  }
`;

export default FeaturedPropertiesQuery;
