import React, { FC } from 'react';
import CustomLink from '../CustomLink';
import Video from '../../Helper/video'
import { BannerType } from './types';
import { ButtonCollection } from '../../Helper/buttonCollection';

type Props = {
  component: {
    banner: BannerType
  }
}

const Banner: FC<Props> = ({component}) => {
    const {title, subTitle, subSubTitle, buttonCollection, buttonTitle, buttonLink, image, fullBleed, fonts, boltCta, video} = component.banner;

    return (
    <div className={`banner-component ${fullBleed && "fullBleed"}`}>
      <div className="banner-image">
        <div className="content-overlay">
          <div className="content">
            <h1>{title}</h1>
            <h3 className={`${fonts ?  "" : "orchide"}`}>{subTitle}</h3>
            <h4>{subSubTitle}</h4>
            <ButtonCollection collection={buttonCollection} />
            {buttonLink ? (
              <CustomLink to={buttonLink} className={`btn ${boltCta && "bold"}`}>{buttonTitle}</CustomLink>
            ): null}
          </div>
        </div>
        {video && <Video video={video} image={image} /> }
        {!video && image && <img alt="" src={image.url}></img> }
      </div>
    </div>
    )
  
}

export default Banner;