import { gql } from "@apollo/client";

export const PropertyListingsQuery = gql`
  query ($id: String!) {
    propertyListings(id: $id) {
      pageTitle
      subTitle
      showFilter
      propertyLocationFiltersCollection {
        items {
          location
          region
          country {
            country
          }
          continent
        }
      }
      propertyTypeFilter
      bedroomFilter
      windowedBanner
      bannerImage {
        title
        url
      }
    }
  }
`;

// export const PropertiesQuery = gql`

// `

export default PropertyListingsQuery;
