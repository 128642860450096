import React, { Component } from 'react'
import { ImageType } from '../../../types'
import { Image } from './image'

type Props = {
  image: ImageType
  video: ImageType
}

type State = Props & {
  playing: boolean;
}

class Video extends Component<Props, State> {
  videoNode: HTMLVideoElement | null | undefined
  constructor(props: Props | Readonly<Props>) {
    super(props)

    this.state = {
      video: this.props.video,
      image: this.props.image,
      playing: false
    }
  }

  render() {
    const { video, image, playing } = this.state
    return (
      <div className="video-container">
        { video ? (
          <>
            { !playing && image && <Image className={`${playing ? "hide-me" : ""}`} src={image.url} alt={image.title} /> }
            <video className="video" playsInline autoPlay muted loop controls={false} ref={ node => this.videoNode = node } src={video.url} />

            {/* <video autoplay="autoplay" loop="loop" muted="muted" playsinline="playsinline" className="bamVideo" src="https://www.brookfield.com/sites/default/files/videos/2018-10/intro_clip.mp4" type="video/mp4" data-autoplay=""></video> */}
          </>
        ) : (
          <Image src={image.url} alt={image.title} />
        )}
      </div>
    )
  }
}

export default Video